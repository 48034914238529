import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import { RadioGroup, Radio, Tag, Icon, Tabs, Tab, Stepper } from 'vant'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Stepper.name]: Stepper
    },
    data () {
        return {
            loadingShow: false,
            comboRadio: 0,
            payRadio: 'wechat',
            payOrderCode: '',
            amount: 0,
            modeActive: 'CMB',
            type: '',
            comboGroup: '',
            comboList: [],
            combo: {},
            paidList: [],
            count: 0
        }
    },
    mounted () {
        var query = this.$route.query
        this.comboGroup = query.group
        this.type = query.type
        this.retrieveComboList()
        this.retrievePaidList()
    },
    methods: {
        comboChange (val) {
            this.combo = this.comboList[val]
            this.amount = this.combo.priceNew
            this.count = this.combo.comboPaid[0].count
        },
        async retrieveComboList () {
            // var appKey = window.sessionStorage.getItem(this.SESSION_APP)
            var pd = { appKey: 'WPP_LHA', group: this.comboGroup }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/combo/retrieveAppGroupComboList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.comboList = res.data
                this.combo = this.comboList[0]
                this.amount = this.combo.priceNew
                this.count = this.combo.comboPaid[0].count
            }
        },
        async retrievePaidList () {
            // var appKey = window.sessionStorage.getItem(this.SESSION_APP)
            var pd = { appKey: 'WPP_LHA', paidKeys: 'HIRE_BEAN' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/paid/retrieveAppCusPaidPriceList', this.$qs.stringify(pd))
            if (res.status === '200') {
                res.data.forEach(element => {
                    element.count = element.step
                    this.paidList.push(element)
                })
            }
        },
        modeChange (val) {
            if (val === 'CMB') {
                this.amount = this.combo.priceNew
            } else if (val === 'CUS') {
                this.cusCalAmount()
            }
            this.modeActive = val
        },

        cusCalAmount () {
            var amount = 0
            var count = 0
            this.paidList.forEach(element => {
                amount += (element.count * element.priceNew) / 10
                count += element.count
            })
            this.amount = amount
            this.count = count
        },
        async payment () {
            this.loadingShow = true
            var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
            var pd = {
                appKey: 'WPP_LHA',
                amount: this.amount,
                attach: '',
                description: '工作帮付费服务购买',
                body: '工作帮付费服务购买',
                detail: '',
                business: '',
                openid: serial
            }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createWJsapiPay', this.$qs.stringify(pd))
            if (res.status === '200') {
                var that = this
                this.payOrderCode = res.data.code
                var param = res.data.param
                window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', param,
                    function (rs) {
                        if (rs.err_msg === 'get_brand_wcpay_request:ok') {
                            that.retrievePayStatus()
                        } else {
                            that.loadingShow = false
                        }
                    })
            }
        },
        async retrievePayStatus () {
            var pd = { appid: 'wx0ae3d729b83399a8', code: this.payOrderCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付成功'
                })
                if (this.type === 'USER') {
                    this.rechargeUserBean()
                } else if (this.type === 'MERCHANT') {
                    this.rechargeMerchantBean()
                }
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '支付失败'
                })
            }
            this.loadingShow = false
        },
        async rechargeUserBean () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user, operate: 'RHE', amount: this.count, attach: '' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/updateBean', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$router.go(-1)
            }
        },
        async rechargeMerchantBean () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var merchant = window.sessionStorage.getItem(this.SESSION_MERCHANT)
            var pd = { merchantCode: merchant, userCode: user, operate: 'RHE', amount: this.count, attach: '' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/updateBean', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$router.go(-1)
            }
        }
    }
}
