<template>
  <div class="page">
    <Navbar title="金豆充值" type="SMP" />
    <van-tabs
      v-model="modeActive"
      :color="COLOR_M"
      border="true"
      @change="modeChange"
    >
      <van-tab title="热门套餐" name="CMB"
        ><van-radio-group
          v-model="comboRadio"
          @change="comboChange"
          :checked-color="COLOR_M"
        >
          <div
            class="combo"
            v-for="(combo, index) in comboList"
            :key="combo.code"
          >
            <van-row>
              <van-col span="4"
                ><van-tag type="primary" :color="COLOR_M">推荐</van-tag
                ><svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-vke"></use></svg
              ></van-col>
              <van-col span="11">
                <div class="name">{{ combo.name }}</div>
                <div class="desc text-short">{{ combo.description }}</div>
              </van-col>
              <van-col span="7"
                ><div class="price color-s1">￥{{ combo.priceNew }}元</div>
                <div class="old-price">￥{{ combo.priceOld }}元</div></van-col
              >
              <van-col span="2"
                ><div class="radio">
                  <van-radio :name="index"></van-radio></div
              ></van-col>
            </van-row>
            <van-row class="count color-s1">
              <van-col
                span="24"
                v-for="paid in combo.comboPaid"
                :key="paid.code"
                >{{ paid.name }}:{{ paid.count }}个金豆</van-col
              >
            </van-row>
          </div>
        </van-radio-group></van-tab
      >
      <van-tab title="自定义组合" name="CUS">
        <div class="customer">
          <van-row class="item" v-for="paid in paidList" :key="paid.code">
            <van-col :span="8" class="text">{{ paid.name }}</van-col>
            <van-col :span="8" class="price color-s1"
              >￥：{{ paid.priceNew }}元</van-col
            >
            <van-col :span="8">
              <van-stepper
                v-model="paid.count"
                min="0"
                :step="paid.step"
                @change="cusCalAmount"
              />
            </van-col>
          </van-row>
        </div>
      </van-tab>
    </van-tabs>
    <van-row class="title">
      <van-col span="12" class="text"
        ><van-icon name="notes-o" /> 支付方式</van-col
      >
    </van-row>
    <van-radio-group v-model="payRadio" :checked-color="COLOR_M">
      <van-row class="pay">
        <van-col span="12">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-wpay"></use>
          </svg>
        </van-col>
        <van-col span="10"
          ><div class="price color-s1">总金额:￥{{ amount }}元</div></van-col
        >
        <van-col span="2"
          ><div class="radio">
            <van-radio name="wechat"></van-radio></div
        ></van-col>
      </van-row>
    </van-radio-group>
    <van-row class="button">
      <van-col offset="7" span="10">
        <van-button
          icon="cart-circle-o"
          @click="rechargeUserBean"
          size="small"
          :color="COLOR_M"
          block
          >立即购买</van-button
        >
      </van-col>
    </van-row>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
